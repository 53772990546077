<!-- @Author: Yu_Bo -->
<template>
	<div class='add_sound zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="940px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">新增声音克隆</div>
      </div>
      <div class="inv_main scroll_bar_x">
        <!-- 客户信息 -->
        <div class="user_info" style="margin-top: 0px;">
          <div class="info_title">客户信息</div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">客户姓名</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">个人客户填写客户名称，企<br/>业用户填写公司名称</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="name" placeholder="请输入客户姓名/公司名称"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">联系电话</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="phone" maxlength="11" placeholder="请输入联系电话"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">客户账号</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-select v-model="account" size="small" filterable remote reserve-keyword placeholder="请选择客户的平台账号"
                    :remote-method="remoteMethod" :loading="selectLoading">
                    <el-option v-for="item in accountList" :key="item.id" :label="item.nickname" :value="item.id">
                      <span style="float: left">{{ item.nickname }}</span>
                      <span style="float: right; color: #2E4BF2; font-size: 14px">训练次数：{{ item.major_cust || 0 }}</span>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">订单金额</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="money" placeholder="请输入订单金额">
                    <template slot="append"><span>元</span></template>
                  </el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">所属项目</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="projectName" placeholder="请输入所属项目"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">客户等级</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">根据客户重要度选择，数值越大<br/>等级越高</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-select size="small" v-model="level" placeholder="请选择客户等级">
                    <el-option
                      v-for="item in levelList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">音频时长</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">此字段仅作记录使用，不会自动充<br/>值，每日管理员会定时查看列表帮<br/>忙充值时长</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-select size="small" v-model="duration" placeholder="请选择需要充值的音频时长">
                    <el-option
                      v-for="item in durationList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">部署平台</span>
              </div>
              <div class="t_right">
                <div class="r_checkbox">
                  <el-checkbox v-model="item.show" v-for="(item,index) in platform_list" :key="index"
                    @change='changeBtn(index,$event)'>{{item.label}}</el-checkbox>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">订单图片</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">如无订单图片，则需要走钉钉审核并<br/>上传钉钉审核截图</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_upimg" v-loading='imgLoading'>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadimg">
                    <el-image v-if="imageUrl" style="width: 100%; height: 100%" :src="imageUrl" fit="cover"></el-image>
                    <img v-else :src="require('@/assets/images/img/up_img.png')" alt="">
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="user_info">
          <div class="info_title">声音信息</div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模型名称</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="model_name" placeholder="请输入模型名称"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模型有效期</span>
              </div>
              <div class="t_right">
                <div class="r_input" v-if="dayShow">
                  <el-input size="small" v-model="dayTime" placeholder="请输入模型有效期">
                    <template slot="append"><span>天</span></template>
                  </el-input>
                </div>
                <div class="r_input" v-else>
                  <el-select size="small" v-model="dayNum" placeholder="请选择模型有效期">
                    <el-option
                      v-for="item in dayList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="r_btn" @click="dayShow=!dayShow">{{dayShow?'取消':'自定义'}}</div>
              </div>
            </div>
          </div>
          <div class="info_con">
            <!--  -->
            <div class="con_left">
              <div class="left_txt">
                <div class="name">头像</div>
                <div class="t_con">
                  <div class="con_img" v-loading='headerLoading'>
                    <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadHeader">
                      <el-avatar :size='88' v-if="headerUrl" :src="headerUrl"></el-avatar>
                      <div class='img_header' v-else>
                        <img src="https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t1.png" alt="">
                        <span>编辑头像</span>
                      </div>
                    </el-upload>
                  </div>
                </div>
              </div>
              <div class="left_txt">
                <div class="name">标签</div>
                <div class="t_con">
                  <el-input
                    type="textarea"
                    :rows="3"
                    resize="none"
                    placeholder="请输入模型标签，多个以“｜”分割"
                    v-model="model_text">
                  </el-input>
                </div>
              </div>
              <!--  -->
              <div class="left_zip">
                <div class="zip_list">
                  <div class="list" v-for="(item,index) in soundList" :key="index">
                    <div class="list_txt">
                      <div class="left">
                        <img src="@/assets/images/icon/zip_icon.png" alt="">
                        <span class="one-txt-cut">{{item.name}}</span>
                      </div>
                      <div class="right"><i class="el-icon-success"></i></div>
                    </div>
                    <div class="list_btn" @click="delBtn(index)"><i class="el-icon-delete"></i></div>
                  </div>
                </div>
                <div class="zip_tip">上传录音中语料时长必须大于20分钟，大小不超过500M，请继续上传</div>
                <div class="zip_btn">
                  <el-upload class="avatar-uploader" action="#" accept=".zip" :show-file-list="false" :http-request="uploadZip">
                    <el-button type="primary" :loading='zipLoading' size="small">上传语料</el-button>
                  </el-upload>
                </div>
                <div class="zip_checkbox">
                  <el-checkbox v-model="modelShow"></el-checkbox>
                  我已阅读并同意<span @click="agreementBtn">《声音克隆协议》</span>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="con_right">
              <div class="t_title">
                <i class="el-icon-success"></i>上传提示
              </div>
              <div class="right_txt">
                <span>1. 有效普通话音频总时长不少于20分钟（方言不少于30分钟），不能包含英文或其他国家语言；</span>
                <span>2、做到吐字清晰，不漏字，不叠字，不错字，句子与名句之间停顿超过0.5秒；</span>
                <span>3、支持使用单条或多条音频文件；</span>
                <span>4、所有语料需为同一个人声音；</span>
              </div>
              <div class="t_title">
                <i class="el-icon-warning"></i>注意事项
              </div>
              <div class="note_list">
                <div class="list" v-for="(item,index) in noteList" :key="index">
                  <img :src="item.img" alt="">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="user_info">
          <div class="info_title">其他信息 <span>*开票信息仅提供云川科技电子普票</span></div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">开票抬头</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_top" placeholder="请输入开票抬头"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">纳税人识别号</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_num" placeholder="请输入纳税人识别号"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">开户银行</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_bank" placeholder="请输入开户银行"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">银行账户</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_acc" placeholder="请输入银行账户"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">公司地址</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_address" placeholder="请输入公司地址"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">联系电话</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_phone" placeholder="请输入联系电话"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">电子邮箱</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_email" placeholder="请输入电子邮箱"></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" style="padding-top: 20px;">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
  import {
      mixinIndex
  } from "@/mixin/index"
	export default {
    mixins: [mixinIndex],
		components: {},
		props: {
      levelList: {
        type: Array,
        defaul: () => []
      },
      dayList: {
        type: Array,
        defaul: () => []
      },
      platform_type: {
        type: Array,
        defaul: () => []
      }
    },
		data() {
			return{
        dialogVisible:false,
        btnLoading:false,
        imgLoading:false,
        headerLoading:false,
        zipLoading:false,
        // 客户信息
        name: '',
        phone: '',
        //
        account: '',
        selectLoading:false,
        accountList: [],
        //
        money: '',
        projectName: '',
        //
        level: '',
        //
        duration: '',
        durationList: [{
            value: 0,
            label: '0分钟'
          },
          {
            value: 300,
            label: '300分钟'
          },
          {
            value: 500,
            label: '500分钟'
          },
          {
            value: 800,
            label: '800分钟'
          },
          {
            value: 1000,
            label: '1000分钟'
          },
        ],
        //
        platform_id: [],
        platform_list: [],
        //
        imageUrl: '',
        imageUrl_short_url: '',
        // 声音信息
        model_name:'',
        dayTime:'',
        dayNum:'',
        dayShow:false,
        headerUrl:'',
        headerUrl_short_url:'',
        soundList:[],
        model_text:'',
        modelShow:false,

        // 其他信息
        other_top:'',
        other_num:'',
        other_bank:'',
        other_acc:'',
        other_address:'',
        other_phone:'',
        other_email:'',
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn() {
        this.platform_list = this.platform_type
        this.dialogVisible = true
      },
      // 取消
      cancelBtn() {
        this.name= ''
        this.phone= ''
        this.account= ''
        this.accountList= []
        this.money= ''
        this.projectName= ''
        this.level= ''
        this.duration= ''
        this.platform_id= []
        this.imageUrl= ''
        this.imageUrl_short_url= ''
        this.model_name= ''
        this.dayTime= ''
        this.dayNum= ''
        this.dayShow= false
        this.headerUrl= ''
        this.headerUrl_short_url= ''
        this.soundList= []
        this.model_text= ''
        this.modelShow= false
        // 其他信息
        this.other_top= ''
        this.other_num= ''
        this.other_bank= ''
        this.other_acc= ''
        this.other_address= ''
        this.other_phone= ''
        this.other_email= ''
        //
        this.dialogVisible = false
      },
      //
      phoneBtn(val) {
        var that = this
        that.selectLoading = true
        var params = {
          mobile: val,
        }
        that.$trainApi.selectMember(params).then(res => {
          that.selectLoading = false
          if (res.code == 1000) {
            that.accountList = res.result
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      remoteMethod(query) {
        if (query !== '' && query.length==11) {
          setTimeout(() => {
            this.phoneBtn(query)
          }, 200);
        } else {
          this.accountList = []
        }
      },
      //
      changeBtn(index, val) {
        this.platform_id = []
        this.platform_list[index].show = val
        this.platform_list.forEach(item => {
          if (item.show) {
            this.platform_id.push(item.value)
          }
        })
        this.$forceUpdate()
      },
      // 上传订单图片
      uploadimg(item) {
          var self = this
          var is_img = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg']
          // 限制图片格式
          const isJPG = is_img.includes(item.file.type)
          // 限制图片大小
          const isLt2M = item.file.size / 1024 / 1024 <= 3;
          if (!isJPG) {
              self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
          }
          if (!isLt2M) {
              self.$errMsg('上传图片大小不能超过 2MB!');
          }
          if (isJPG && isLt2M) {
            self.imgLoading=true
              let newFile = new FormData()
              newFile.append('type', 1); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
              newFile.append('file', item.file);
              self.$publicApi.uploadFile(newFile).then(res => {
                self.imgLoading=false
                  if (res.code == 1000) {
                      self.imageUrl = res.result.url
                      self.imageUrl_short_url = res.result.short_url
                  } else {
                      self.$errMsg(res.message)
                  }
              })
          }
      },
      // 头像
      uploadHeader(item){
        var self = this
        var is_img = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg']
        // 限制图片格式
        const isJPG = is_img.includes(item.file.type)
        // 限制图片大小
        const isLt2M = item.file.size / 1024 / 1024 <= 3;
        if (!isJPG) {
            self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
        }
        if (!isLt2M) {
            self.$errMsg('上传图片大小不能超过 2MB!');
        }
        if (isJPG && isLt2M) {
          self.headerLoading=true
            let newFile = new FormData()
            newFile.append('type', 1); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
            newFile.append('file', item.file);
            self.$publicApi.uploadFile(newFile).then(res => {
              self.headerLoading=false
                if (res.code == 1000) {
                    self.headerUrl = res.result.url
                    self.headerUrl_short_url = res.result.short_url
                } else {
                    self.$errMsg(res.message)
                }
            })
        }
      },
      // 上传语料
      uploadZip(item){
        var that = this
        var file = item.file
        const filmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const isZip = filmsg === 'zip'
        const isLt500M = file.size / 1024 / 1024 < 500;
        if (!isZip) {
          that.$message.error("只能上传zip格式压缩文件!");
          return
        }
        if (!isLt500M) {
          that.$message.error("上传文件大小不能超过 500MB!");
          return
        }
        var name = file.name
        that.zipLoading = true
        let newFile = new FormData()
        newFile.append('file', item.file)
        that.$publicApi.uploadAudio(newFile).then(res => {
          that.zipLoading = false
          if (res.code == 1000) {
            that.soundList.push({
                name: name,
                url: res.result.short_url
            })
          } else {
            that.$message.error(res.message);
          }
        })
      },
      // 删除语料
      delBtn(index) {
          this.soundList.splice(index, 1)
      },
      // 协议
      agreementBtn(){
        return
        const { href } = this.$router.resolve({
          path: "/textPage/"+1,
        });
        window.open(href, "_blank")
      },
      // 提交
      affirmBtn(){
        var that = this
        if(that.name==''){
          that.$warMsg('请输入客户姓名/公司名称')
        }else if(that.phone==''){
          that.$warMsg('请输入联系电话')
        }else if(that.account==''){
          that.$warMsg('请选择客户的平台账号')
        }else if(that.money==''){
          that.$warMsg('请输入订单金额')
        }else if(that.projectName==''){
          that.$warMsg('请输入所属项目')
        }else if(that.duration==''){
          that.$warMsg('请选择需要充值的音频时长')
        }else if(that.imageUrl==''){
          that.$warMsg('请上传订单图片')
        }else if(that.model_name==''){
          that.$warMsg('请输入模型名称')
        }else if(that.dayTime=='' && that.dayShow){
          that.$warMsg('请输入模特有效期')
        }else if(that.dayNum=='' && !that.dayShow){
          that.$warMsg('请选择模特有效期')
        }else if(that.soundList.length==0){
          that.$warMsg('请上传语料')
        }else if(!that.modelShow){
          that.$warMsg('请阅读并同意声音克隆协议')
        }else{
          that.btnLoading=true
          var invoiceText = {
            invoice_title:that.other_top,
            invoice_identifier:that.other_num,
            bankname:that.other_bank,
            bank_account:that.other_acc,
            address:that.other_address,
            mobile:that.other_phone,
            email:that.other_email,
          }
          var params = {
            nickname:that.name,
            phone:that.phone,
            order_money:that.money,
            member_id:that.account,
            project_name:that.projectName,
            member_leve:that.level,
            audio_duration:that.duration,
            platform_ids:that.platform_id,
            order_img:that.imageUrl_short_url,
            name:that.model_name,
            expiry_date:that.dayShow?that.dayTime:that.dayNum,
            avatar:that.headerUrl_short_url?that.headerUrl_short_url:'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t1.png',
            label:that.model_text,
            audio_file:that.soundList,
            invoice:invoiceText
          }
          that.$trainApi.ttsListPost(params).then(res => {
            that.btnLoading=false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.add_sound{
    ::v-deep .el-dialog__body{
      padding: 0;
      padding-right: 10px;
    }
    .inv_main {
      width: 100%;
      padding: 20px 17px 0px 20px;
      height: 500px;
      overflow-y: auto;
      .user_info{
        width: 100%;
        margin-top: 20px;
        padding: 0 20px 20px;
        background: #F7F7F7;
        .info_title{
          width: 100%;
          line-height: 50px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-bottom: 1px solid #E7E7E7;
          span{
            padding-left: 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #F2912E;
          }
        }
        .info_txt{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .txt{
            width: 50%;
            padding-top: 20px;
            display: flex;
            .t_left{
              width: 100px;
              height: 32px;
              display: flex;
              align-items: center;
              .left_d{
                display: block;
                width: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF2B2B;
              }
              .left_t{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              img{
                display: block;
                width: 14px;
                height: 14px;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
            .t_right{
              display: flex;
              .r_input{
                width: 210px;
                .el-input{
                  width: 100%;
                }
                .el-select{
                  width: 100%;
                }
                ::v-deep .el-input-group__append{
                  padding: 0 10px;
                  color: #fff;
                  background: #2E4BF2;
                }
              }
              .r_checkbox{
                padding-left: 15px;
                display: flex;
                flex-wrap: wrap;
                .el-checkbox{
                  padding-top: 7px;
                  margin-right: 10px;
                }
              }
              .r_upimg{
                width: 90px;
                height: 90px;
              }
              .r_btn{
                cursor: pointer;
                line-height: 32px;
                margin-left: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2E4BF2;
              }
            }
          }
        }
        .info_con{
          width: 100%;
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          .con_left{
            width: 420px;
            .left_txt{
              width: 100%;
              padding-bottom: 20px;
              display: flex;
              .name{
                width: 100px;
                line-height: 32px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              .t_con{
                width: 320px;
                padding-right: 40px;
                .con_img{
                  cursor: pointer;
                  width: 88px;
                  height: 88px;
                  border-radius: 50%;
                  img{
                    display: block;
                    width: 100%;
                    height: 100%;
                  }
                  .img_header{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    span{
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 100%;
                      line-height: 88px;
                      text-align: center;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #FFFFFF;
                      background: rgba(0, 0, 0, 0.5);
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
            .left_txt:nth-child(2){
              border-bottom: 1px solid #E7E7E7;
            }
            /*  */
            .left_zip{
              width: 100%;
              padding-top: 20px;
              padding-right: 50px;
              .zip_list{
                width: 100%;
                .list{
                  width: 100%;
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .list_txt{
                    width: 310px;
                    height: 48px;
                    padding: 0 15px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .left{
                      width: 260px;
                      display: flex;
                      align-items: center;
                      img{
                        display: block;
                        width: 15px;
                        height: 15px;
                      }
                      span{
                        display: block;
                        padding-left: 10px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                      }
                    }
                    .right .el-icon-success{
                      color: #FF7432;
                    }
                  }
                  .list_btn{
                    cursor: pointer;
                    width: 48px;
                    height: 48px;
                    background: rgba(46,75,242,0.08);
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .el-icon-delete{
                      font-size: 16px;
                      color: #2B4AFE;
                    }
                  }
                }
              }
              .zip_tip{
                width: 100%;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF495E;
              }
              .zip_btn{
                padding-top: 20px;
              }
              .zip_checkbox{
                padding-top: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                span{
                  cursor: pointer;
                  color: #2E4BF2;
                }
                .el-checkbox{
                  margin-right: 5px;
                }
              }
            }
          }
          .con_right{
            width: 425px;
            padding: 20px;
            background: #FFFFFF;
            border-radius: 4px;
            .t_title{
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              .el-icon-success{
                font-size: 16px;
                color: #FCBD93;
                margin-right: 5px;
                margin-top: 2px;
              }
              .el-icon-warning{
                font-size: 16px;
                color: #FC9393;
                margin-right: 5px;
                margin-top: 2px;
              }
            }
            .right_txt{
              width: 100%;
              padding: 20px 0 10px;
              span{
                padding-bottom: 10px;
                display: block;
                line-height: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9097A5;
              }
            }
            .note_list{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              .list{
                padding-top: 20px;
                padding-right: 30px;
                img{
                  display: block;
                  width: 60px;
                  height: 38px;
                  margin: 0 auto 12px;
                }
                span{
                  font-size: 14px;
                  text-align: center;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                }
              }
              .list:nth-child(3n){
                padding-right: 0;
              }
            }
          }
        }
      }
    }
	}
</style>
