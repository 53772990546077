<!-- @Author: Yu_Bo -->
<template>
	<div class='edit_info zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="534px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">编辑客户信息</div>
      </div>
      <div class="inv_main" v-loading='loading'>
        <div class="main_text">
          <div class="left">客户姓名</div>
          <div class="right">
            <el-input v-model="name" placeholder="请输入客户姓名" maxlength="10" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left">联系电话</div>
          <div class="right">
            <el-input v-model="phone" placeholder="请输入联系电话" maxlength="11" show-word-limit>
            </el-input>
          </div>
        </div>
        <div class="main_text">
          <div class="left">客户账号</div>
          <div class="right">
            <el-select v-model="roleValue" placeholder="请选择客户账号">
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
                <span style="float: left">{{ item.nickname }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">训练次数：{{ item.major_cust }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div slot="footer" style="padding:0 20px;">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        loading:false,
        id:'',
        name:'',
        phone:'',
        roleValue:'',
        roleList:[],
        btnLoading:false,
        //
        dialogVisible:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(info) {
        this.dialogVisible = true
        this.memberInfo(info)
      },
      memberInfo(info){
        var that = this
        that.loading=true
        var params = {
          mobile:info.phone,
        }
        that.$trainApi.ttsListSelectMember(params).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.roleList=res.result
            that.id=info.id
            that.name=info.nickname
            that.phone=info.phone
            that.roleValue=info.member_id
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 取消
      cancelBtn() {
        this.dialogVisible = false
      },
      // 确定
      affirmBtn(){
        var that = this
        if(that.name==''){
          that.$errMsg('请输入名称')
          return
        }else if(that.phone==''){
          that.$errMsg('请输入联系电话')
          return
        }else if(that.roleValue==''){
          that.$errMsg('请选择客户账号')
          return
        }else{
          // 编辑
          that.btnLoading=true
          var params = {
            id:that.id,
            nickname:that.name,
            phone: that.phone,
            member_id:that.roleValue,
          }
          that.$trainApi.ttsListPut(params,that.id).then(res => {
            that.btnLoading=false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.edit_info{
    .inv_main {
      width: 100%;
      padding: 10px 20px 0 0;

      .main_text {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .left {
          width: 80px;
          line-height: 40px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;

          span {
            color: #E00C25;
          }
        }

        .right {
          width: 375px;

          .el-input {
            width: 100%;
          }

          .el-select {
            width: 100%;
          }
          .el-cascader{
            width: 100%;
          }
          .right_txt{
            padding-top: 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF2B2B;
          }
        }
      }
      .main_text:last-child {
        margin-bottom: 0;
      }
    }
	}
</style>
