import { render, staticRenderFns } from "./addSound.vue?vue&type=template&id=700de899&scoped=true&"
import script from "./addSound.vue?vue&type=script&lang=js&"
export * from "./addSound.vue?vue&type=script&lang=js&"
import style0 from "./addSound.vue?vue&type=style&index=0&id=700de899&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700de899",
  null
  
)

export default component.exports