<!-- @Author: Yu_Bo -->
<template>
	<div class='sound_preview zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="690px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">预览</div>
      </div>
      <div class="video_main">
        <div class="main_con">
          <el-image style="width: 100%; height: 100%" :src="image" fit="cover"></el-image>
          <div class="play_box">
            <audio-player :ref="'play' + 0" :index='0' :fileurl='audio'></audio-player>
          </div>
          <audio :src="audio" loop="loop"></audio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btnBgColor_blue_empty_no_grey" size="small">下载音频</el-button>
        <el-button class="btnBgColor_blue" size="small" type="primary">复制音频链接</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
  import audioPlayer from '@/components/audioPlayer/indexXin.vue'
	export default {
		components: {audioPlayer},
		props: {},
		data() {
			return{
        dialogVisible:false,
        image: require('@/assets/images/img/audio_bg.png'),
        playShow:false,
        audio: 'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/video/20230619/oPIWkBFARSjV-POnEw.mp4'
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.dialogVisible = true
        // this.audio=val
      },
      // 取消
      cancelBtn() {
        this.$refs.play0.pauseAudio()
        this.dialogVisible = false
      },
    },
	}
</script>

<style lang='scss' scoped>
	.sound_preview{
    .video_main{
      width: 100%;
      .main_con{
        position: relative;
        width: 486px;
        height: 274px;
        margin: auto;
        background: #000;
        audio {
          display: block;
          width: 100%;
          height: 100%;
        }
        .play_box{
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          width: 100%;
        }
      }
    }
	}
</style>
