<!-- @Author: Yu_Bo -->
<template>
	<div class='edit_info zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="400px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">重新上传</div>
      </div>
      <div class="inv_main">
        <div class="zip_list">
          <div class="list" v-for="(item,index) in soundList" :key="index">
            <div class="list_txt">
              <div class="left">
                <img src="@/assets/images/icon/zip_icon.png" alt="">
                <span class="one-txt-cut">{{item.name}}</span>
              </div>
              <div class="right"><i class="el-icon-success"></i></div>
            </div>
            <div class="list_btn" @click="delBtn(index)"><i class="el-icon-delete"></i></div>
          </div>
        </div>
        <div class="zip_tip">上传录音中语料时长必须大于20分钟，大小不超过500M，请继续上传</div>
        <div class="zip_btn">
          <el-upload class="avatar-uploader" action="#" accept=".zip" :show-file-list="false" :http-request="uploadZip">
            <el-button type="primary" :loading='zipLoading' size="small">上传语料</el-button>
          </el-upload>
        </div>
      </div>
      <div slot="footer">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        btnLoading:false,
        id:'',
        soundList:[],
        zipLoading:false,
        //
        dialogVisible:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.dialogVisible = true
        this.id=val
      },
      // 取消
      cancelBtn() {
        this.id=''
        this.soundList=[]
        this.dialogVisible = false
      },
      uploadZip(item){
        var that = this
        var file = item.file
        const filmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
        const isZip = filmsg === 'zip'
        const isLt500M = file.size / 1024 / 1024 < 500;
        if (!isZip) {
          that.$message.error("只能上传zip格式压缩文件!");
          return
        }
        if (!isLt500M) {
          that.$message.error("上传文件大小不能超过 500MB!");
          return
        }
        var name = file.name
        that.zipLoading = true
        let newFile = new FormData()
        newFile.append('file', item.file)
        that.$publicApi.uploadAudio(newFile).then(res => {
          that.zipLoading = false
          if (res.code == 1000) {
            that.soundList.push({
                name: name,
                url: res.result.short_url
            })
          } else {
            that.$message.error(res.message);
          }
        })
      },
      // 删除语料
      delBtn(index) {
          this.soundList.splice(index, 1)
      },
      // 确定
      affirmBtn(){
        var that = this
        if(that.soundList.length==0){
          that.$warMsg('请上传语料')
          return
        }else{
          // 编辑
          that.btnLoading=true
          var params = {
            id:that.id,
            audio_file: that.soundList,
          }
          that.$trainApi.ttsListUpAudio(params).then(res => {
            that.btnLoading=false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.edit_info{
    .inv_main {
      width: 100%;
      padding: 0 20px 0 0;
      .zip_list{
        width: 100%;
        .list{
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .list_txt{
            width: 280px;
            height: 48px;
            padding: 0 15px;
            background: #eee;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left{
              width: 260px;
              display: flex;
              align-items: center;
              img{
                display: block;
                width: 15px;
                height: 15px;
              }
              span{
                display: block;
                padding-left: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
            }
            .right .el-icon-success{
              color: #FF7432;
            }
          }
          .list_btn{
            cursor: pointer;
            width: 48px;
            height: 48px;
            background: rgba(46,75,242,0.08);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-icon-delete{
              font-size: 16px;
              color: #2B4AFE;
            }
          }
        }
      }
      .zip_tip{
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF495E;
      }
      .zip_btn{
        padding-top: 20px;
      }
    }
	}
</style>
