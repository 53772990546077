<!-- @Author: Yu_Bo -->
<template>
	<div class='audio_details zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="852px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">声音克隆详情</div>
      </div>
      <div class="inv_main scroll_bar_x" style="padding-bottom: 20px;" v-loading='loading'>
        <!-- 客户信息 -->
        <div class="main_text" style="margin-top: 0;">
          <div class="text_title">客户信息</div>
          <div class="text_info">
            <div class="info_txt">
              <div class="t_left">客户姓名：</div>
              <div class="t_right">{{nickname}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">联系电话：</div>
              <div class="t_right">{{phone}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">客户账号：</div>
              <div class="t_right">{{member_name}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">订单金额：</div>
              <div class="t_right">{{order_money}}元</div>
            </div>
            <div class="info_txt">
              <div class="t_left">所属项目：</div>
              <div class="t_right">{{project_name}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">客户等级：</div>
              <div class="t_right">{{member_leve}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">音频时长：</div>
              <div class="t_right">{{audio_duration}}分钟</div>
            </div>
            <div class="info_txt">
              <div class="t_left">部署平台：</div>
              <div class="t_right">{{platform_name}}</div>
            </div>
            <!-- 图片 -->
            <div class="info_txt" v-if="order_img">
              <div class="t_left">订单图片：</div>
              <div class="t_orderImg">
                <el-image
                  style="width: 38px; height: 80px"
                  :src="order_img"
                  fit='cover'
                  :preview-src-list="srcList">
                </el-image>
              </div>
            </div>
            <div class="info_two">
              <div class="info_txt">
                <div class="t_left">创建时间：</div>
                <div class="t_right">{{create_time}}</div>
              </div>
              <div class="info_txt" v-if="audit_time">
                <div class="t_left">创建时间：</div>
                <div class="t_right">{{audit_time}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 模特信息 -->
        <div class="main_text">
          <div class="text_title">模特信息</div>
          <div class="text_info">
            <div class="info_txt">
              <div class="t_left">模型名称：</div>
              <div class="t_right">{{name}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">模型有效期：</div>
              <div class="t_right">{{expiry_date || '--'}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">头像：</div>
              <div class="t_img">
                <el-avatar :size='88' :src="avatar" fit='cover'></el-avatar>
              </div>
            </div>
            <div class="info_txt">
              <div class="t_left">标签：</div>
              <div class="t_right">{{label}}</div>
            </div>
            <div class="info_txt">
              <div class="t_left">上传语料：</div>
              <div class="t_zip">
                <div class="zip_txt" v-for="(item,index) in audio_file" :key="index">
                  <img src="@/assets/images/icon/zip_icon.png" alt="">
                  <span class="one-txt-cut">{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 其他信息 -->
        <div class="main_text">
          <div class="text_title">其他信息</div>
          <div class="text_info">
            <div class="info_txt" v-for="(item,index) in infoList" :key="index">
              <div class="t_left">{{item.name}}</div>
              <div class="t_right">{{item.text}}</div>
            </div>
          </div>
        </div>
        <!-- 失败原因 -->
        <div class="main_txt" v-if="remark">
          <div class="txt_left">失败原因：</div>
          <div class="txt_right">{{remark}}</div>
        </div>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      platform_type:{
        type:Array,
        defaul:()=>[]
      }
    },
		data() {
			return{
        dialogVisible:false,
        loading:false,
        //
        nickname:'',
        phone:'',
        member_name:"",
        order_money:'',
        project_name:'',
        member_leve:'',
        audio_duration:'',
        platform_ids:'',
        platform_name:'',
        order_img:'',
        create_time:'',
        audit_time:'',
        name:'',
        expiry_date:'',
        avatar:"",
        label:'',
        audio_file:[],
        remark:"",
        //
        invoice_title:"",
        invoice_identifier:"",
        bankname:"",
        bank_account:"",
        address:"",
        mobile:"",
        email:"",
        //
        img:'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        srcList:[
          'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
        ]
      }
		},
		computed: {
      // 其他信息
      infoList(){
        return [
          {name:'开票抬头：',text:this.invoice_title},
          {name:'纳税人识别号：',text:this.invoice_identifier},
          {name:'开户银行：',text:this.bankname},
          {name:'银行账户：',text:this.bank_account},
          {name:'公司地址：',text:this.address},
          {name:'联系电话：',text:this.mobile},
          {name:'电子邮箱：',text:this.email},
        ]
      },
    },
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.dialogVisible = true
        this.infoDetails(val)
      },
      // 取消
      cancelBtn() {
        this.dialogVisible = false
      },
      // 详情
      infoDetails(val){
        var that = this
        that.loading=true
        that.$trainApi.ttsListDetails(val).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.nickname=res.result.nickname
            that.phone=res.result.phone
            that.member_name=res.result.member_name
            that.order_money=res.result.order_money
            that.project_name=res.result.project_name
            that.member_leve=res.result.member_leve
            that.audio_duration=res.result.audio_duration
            that.platform_ids=res.result.platform_ids
            if(that.platform_ids){
              var str = that.platform_ids.split(',')
              that.platformBtn(str)
            }
            that.order_img=res.result.order_img
            that.create_time=res.result.create_time
            that.audit_time=res.result.audit_time
            that.name=res.result.name
            that.expiry_date=res.result.expiry_date
            that.avatar=res.result.avatar
            that.label=res.result.label
            that.audio_file=res.result.audio_file
            that.remark=res.result.remark

            if(res.result.invoice){
              that.invoice_title=res.result.invoice.invoice_title
              that.invoice_identifier=res.result.invoice.invoice_identifier
              that.bankname=res.result.invoice.bankname
              that.bank_account=res.result.invoice.bank_account
              that.address=res.result.invoice.address
              that.mobile=res.result.invoice.mobile
              that.email=res.result.invoice.email
            }
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      platformBtn(str){
        var that = this
        var arr = []
        for(var i=0;i<str.length;i++){
          for(var n=0;n<that.platform_type.length;n++){
            if(str[i]==that.platform_type[n].value){
              arr.push(that.platform_type[n].label)
            }
          }
        }
        that.platform_name=arr.join(',')
      },
    },
	}
</script>

<style lang='scss' scoped>
	.audio_details{
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    .inv_main {
      width: 100%;
      padding: 20px;
      height: 500px;
      overflow-y: auto;
      .main_text{
        width: 100%;
        padding: 0 20px;
        margin-top: 20px;
        background: #F7F7F7;
        .text_title{
          width: 100%;
          line-height: 50px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-bottom: 1px solid #E7E7E7;
        }
        .text_info{
          width: 100%;
          padding: 10px 0;
          display: flex;
          flex-wrap: wrap;
          .info_txt{
            width: 50%;
            line-height: 40px;
            display: flex;
            .t_left{
              width: 100px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
            .t_right{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .t_img{
              height: 105px;
            }
            .t_orderImg{
              height: 90px;
              margin-top: 10px;
            }
            .t_zip{
              width: 220px;
              margin-top: 10px;
              margin-bottom: 10px;
              padding: 0 15px;
              background: #fff;
              border-radius: 4px;
              .zip_txt{
                width: 100%;
                display: flex;
                align-items: center;
                img{
                  display: block;
                  width: 15px;
                  height: 15px;
                }
                span{
                  padding-left: 15px;
                }
              }
            }
          }
          .info_two{
            width: 50%;
            height: 80px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .info_txt{
              width: 100%;
              line-height: 40px;
              display: flex;
            }
          }
        }
      }
      .main_txt{
        width: 100%;
        padding: 0 20px;
        margin-top: 20px;
        height: 60px;
        background: #F7F7F7;
        display: flex;
        align-items: center;
        .txt_left{
          width: 100px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .txt_right{
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
	}
</style>
