<template>
  <div class="audio-box">
    <audio @timeupdate="updateProgress" :ref="'audioRef'+index" :src="fileurl" hidden></audio>
    <div class="audio-right">
      <div class="dialogAudioPlay" @click="playAudio">
        <img v-if="audioStatus !== 'pause'" src="@/assets/images/img/pay_icon2.png" alt="">
        <img v-else src="@/assets/images/img/pay_icon1.png" alt="">
      </div>
      <div class="progress-bar-bg" :id="'progressBarBg'+index" v-dragto="setAudioIcon">
        <div class="progress-bar" :id="'progressBar'+index"></div>
      </div>
      <div class="audio-time" style="min-height: 10px">
        <span class="audio-length-current" :id="'audioCurTime'+index">{{ audioStart }}</span>/
        <span class="audio-length-total">{{ duration }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  var that = this
  export default {
    props: {
      index: {
        trpe: Number
      },
      fileurl: {
        trpe: String
      },
    },
    data() {
      return {
        audioStatus: 'play',
        audioStart: '0:00',
        duration: '0:00',
      }
    },

    directives: {
      dragto: {
        inserted: function(el, binding, vnode) {
          el.addEventListener(
            'click',
            (e) => {
              var progressBarBg = 'progressBarBg'+vnode.context.index
              var progressBar = 'progressBar'+vnode.context.index
              let wdiv = document.getElementById(progressBarBg).clientWidth
              let audio = eval('vnode.context.$refs.audioRef' + vnode.context.index)
              // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
              let ratemin = e.offsetX / wdiv
              let rate = ratemin * 100
              document.getElementById(progressBar).style.width = rate + '%'
              audio.currentTime = audio.duration * ratemin
              // 点击进度条播放
              // audio.play()
              // binding.value()
            },
            false
          )
        }
      },
    },
    computed: {},
    mounted() {
      this.fetch()
    },
    methods: {
      fetch() {
        let that = this
        var myVid = eval('this.$refs.audioRef' + this.index)
        var progressBar = 'progressBar'+this.index
        myVid.loop = false
        // 监听音频播放完毕
        myVid.addEventListener(
          'ended',
          function() {
            that.audioStatus = 'play' // 显示播放icon
            document.getElementById(progressBar).style.width = '0%' // 进度条初始化
          },
          false
        )
        if (myVid != null) {
          myVid.oncanplay = function() {
            that.duration = that.transTime(myVid.duration) // 计算音频时长
          }
          myVid.volume = 0.5 // 设置音量50%
        }
      },
      // 暂停
      pauseAudio(){
        let recordAudio = eval('this.$refs.audioRef' + this.index)
        recordAudio.pause()
        this.audioStatus = 'play'
      },
      // 播放暂停控制
      playAudio() {
        let recordAudio = eval('this.$refs.audioRef' + this.index)
        if (recordAudio.paused) {
          recordAudio.play()
          this.audioStatus = 'pause'
        } else {
          recordAudio.pause()
          this.audioStatus = 'play'
        }
        this.$emit('playAudio',this.index)
      },
      // 更新进度条与当前播放时间
      updateProgress(e) {
        var progressBar = 'progressBar'+this.index
        let audioRef = eval('this.$refs.audioRef' + this.index)
        var value = e.target.currentTime / e.target.duration
        if (document.getElementById(progressBar)) {
          document.getElementById(progressBar).style.width = value * 100 + '%'
          if (e.target.currentTime === e.target.duration) {
            this.audioStatus = 'pause'
          }
        } else {
          this.audioStatus = 'pause'
        }
        this.audioStart = this.transTime(audioRef.currentTime)
      },
      /**
       * 音频播放时间换算
       * @param {number} value - 音频当前播放时间，单位秒
       */
      transTime(time) {
        var duration = parseInt(time)
        var minute = parseInt(duration / 60)
        var sec = (duration % 60) + ''
        var isM0 = ':'
        if (minute === 0) {
          minute = '00'
        } else if (minute < 10) {
          minute = '0' + minute
        }
        if (sec.length === 1) {
          sec = '0' + sec
        }
        return minute + isM0 + sec
      },
      setAudioIcon() {
        this.audioStatus = 'pause'
      },
    }
  }
</script>

<style lang="scss" scoped>
  .audio-box {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.96) 100%);
    line-height: 50px;
  }

  .volume {
    position: relative;

    .volume-progress {
      position: absolute;
      top: -150px;
      width: 32px;
      height: 140px;
      background: #f6f6f6;
      border-radius: 4px;
      padding-top: 10px;
    }

    .volume-bar-bg {
      margin: 0 auto;
      width: 6px;
      height: 120px;
      background: #dcdcdc;
      border-radius: 100px;
      flex: 1;
      position: relative;
      transform: rotate(180deg);
      cursor: pointer;

      .volume-bar {
        width: 6px;
        height: 50%;
        background: #56bf8b;
        border-radius: 100px;
      }
    }

    .checked {
      color: #56bf8b;
    }
  }

  .audio-right {
    // width: 100%;
    // height: 49px;
    // line-height: 49px;
    background: transparent;
    border-radius: 6px;
    display: flex;
    padding: 0 15px;
    align-items: center;

    .dialogAudioPlay {
      cursor: pointer;
      // color: #fff;
      // font-size: 20px;
      width: 20px;
      height: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .progress-bar-bg {
      background: rgba(255, 255, 255, 0.4);
      flex: 1;
      position: relative;
      height: 5px;
      cursor: pointer;
      margin: 0 10px;
      border-radius: 3px;
    }

    .progress-bar {
      background-color: #FFFFFF;
      width: 0%;
      height: 5px;
      border-radius: 5px;
    }

    .audio-time {
      overflow: hidden;
      font-size: 12px;
      color: #fff;

      .audio-length-total {
        float: right;
      }

      .audio-length-current {
        float: left;
      }
    }
  }
</style>
