<!-- @Author: Yu_Bo -->
<template>
	<div class='screen_model'>
    <div class="left">
      <el-button size="small" class="btnBgColor_blue_empty_no" icon="el-icon-plus" @click="addBtn">新增声音克隆</el-button>
      <div class="left_txt">
        <el-input size="small" v-model="sound_name" @change="searchBtn" :clearable="true" placeholder="输入声音克隆"></el-input>
      </div>
      <div class="left_txt">
        <el-input size="small" v-model="name" @change="searchBtn" :clearable="true" placeholder="请输入客户姓名/手机"></el-input>
      </div>
      <div class="left_txt">
        <el-input size="small" v-model="project" @change="searchBtn" :clearable="true" placeholder="输入所属项目"></el-input>
      </div>
      <div class="left_txt" style="width: 150px;">
        <el-select v-model="status" size="small" placeholder="选择审核状态" @change="searchBtn" :clearable="true">
            <el-option v-for="item in status_list" :key="item.value" :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
      </div>
      <div class="left_txt" style="width: 150px;">
        <el-select v-model="recharge" size="small" placeholder="选择充值状态" @change="searchBtn" :clearable="true">
            <el-option v-for="item in recharge_list" :key="item.value" :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
      </div>
      <div class="left_txt" style="width: 370px;">
        <div class="txt">创建时间</div>
        <el-date-picker size='small' v-model="dateTime" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
            @change="searchBtn" :clearable="true">
        </el-date-picker>
      </div>
    </div>
    <div class="right">
      <el-button size="small" icon="el-icon-upload2" @click="exportBtn">导出</el-button>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      pay_type:{
        type:Array,
        defaul: ()=>[]
      },
      status_list:{
        type:Array,
        defaul: ()=>[]
      },
      recharge_list:{
        type:Array,
        defaul: ()=>[]
      }
    },
		data() {
			return{
        sound_name:'',
        name:'',
        project:'',
        // 
        status:'',
        // 
        recharge:'',
        // 
        dateTime:''
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 添加
      addBtn(){
        this.$emit('addSound')
      },
      // 搜索
      searchBtn(){
        var obj = {
          name:this.sound_name,
          name_mobile:this.name,
          project_name:this.project,
          status:this.status,
          recharge_type:this.recharge,
          started:this.dateTime?`${this.dateTime[0]}`:'',
          ended:this.dateTime?`${this.dateTime[1]}`:'',
        }
        this.$emit('search',obj)
      },
      // 导出
      exportBtn(){},
    },
	}
</script>

<style lang='scss' scoped>
	.screen_model{
    width: 100%;
    padding: 10px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .btnBgColor_blue_empty_no{
        margin-top: 10px;
      }
      .left_txt{
        width: 170px;
        margin-left: 20px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        .txt{
          width: 70px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .el-date-editor{
          width: 300px;
        }
      }
    }
    .right{
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
	}
</style>
